/* App.css */
@tailwind base;
@tailwind components;
@tailwind utilities;

/* Your custom styles */
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

/* Mobile logo positioning */
@media (max-width: 768px) {
  .App-logo, .MuiToolbar-root img {
    position: absolute !important;
    left: 0 !important;
    margin: 0 !important;
    padding: 0 !important;
    transform: none !important;
  }
  
  .MuiToolbar-root {
    padding: 0 !important;
    min-height: auto !important;
    margin: 0 !important;
  }

  .MuiToolbar-gutters {
    padding: 0 !important;
    margin: 0 !important;
  }

  /* Override any potential Box padding */
  .MuiBox-root {
    padding-left: 0 !important;
  }

  /* Override any container padding */
  .MuiContainer-root {
    padding-left: 0 !important;
  }

  /* Override any AppBar padding */
  .MuiAppBar-root {
    padding: 0 !important;
  }

  /* Reset text alignment */
  .App {
    text-align: left !important;
  }
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

body {
  background-color: #FFFFFF;
}

.App-header {
  background-color: #5d4280;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  transition: background-color 0.3s;
}

@keyframes glow {
  from {
    box-shadow: 0 0 5px #aaa;
  }
  to {
    box-shadow: 0 0 20px #ffa;
  }
}

.glow {
  animation: glow 2s infinite alternate;
}

/* Ensure AppBar is sticky */
.MuiAppBar-root {
  position: sticky;
  top: 0;
  z-index: 1100;
}

.App-link {
  color: #ffe09d;
}

.modal-image-lightbox {
  max-width: 50%;
  max-height: 50%;
}

.modal-image-img {
  width: 100%;
  height: auto;
}

.gallery-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: auto;
  grid-gap: 10px;
}

.header-image {
  grid-column: 1 / -1;
  height: 100%;
  object-fit: cover;
}

.gallery-image {
  height: 100%;
  object-fit: cover;
}

.footer-background {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.footer-blue {
  background-image: url('/public/BlueFooter.svg');
  background-repeat: no-repeat;
  background-position: bottom;
  background-size: cover;
  z-index: 1;
  padding: 4rem 0 0 0;
  position: relative;
  min-height: 400px;
  display: flex;
  flex-direction: column;
}

.ai-makers-bar {
  position: relative;
  width: 100%;
  background-color: #1A3D8F;
  color: white;
  padding: 0.75rem 0;
  text-align: center;
  font-size: 0.9rem;
  margin-top: auto;
}

.ai-makers-bar a {
  color: white;
  text-decoration: none;
  font-weight: 600;
}

.ai-makers-bar a:hover {
  text-decoration: underline;
  color: #FFD700;
}

.admin-button:hover {
  background-color: #1a237e;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}